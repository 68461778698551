import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import './cclStats.scss';

const stats = [
  {
    id: 'leaders',
    stat: '6K',
    about: 'Social sector leaders trained last year'
  },
  {
    id: 'countries',
    stat: '160+',
    about: "Countries where we’ve impacted communities"
  },
  {
    id: 'scholarships',
    stat: '$800K',
    about: 'Awarded in scholarships annually '
  }
];

const animateValue = (id, endString, end) => {
  let range = end - 0;
  let current = 0;
  let increment = end > 0 ? 1 : -1;
  let stepTime = Math.abs(Math.floor(1000 / range));
  let obj = document.getElementById(id);

  let money = '';
  if (id === 'scholarships') {
    money = '$';
  };

  let timer = setInterval(function () {
    current += increment;
    obj.innerHTML = money + current + endString;
    if (current === end) {
      clearInterval(timer);
    }
  }, stepTime);
};

let counted = false;

const onVisible = isVisible => {
  if (isVisible && !counted) {
    animateValue('leaders', 'K', 6);
    animateValue('countries', '+', 160);
    animateValue('scholarships', 'K', 800);
    counted = true;
  }
};

const CommunityStats = ({ isVisible }) => {
  return (
    <div className="ccl-stats">
      <div className="stats">
        <h2 className="title">
          CCL's mission is to make a bigger impact in society by creating stronger community leadership for today &mdash; and tomorrow.
        </h2>
        <VisibilitySensor onChange={onVisible}>
          <div className="stats-content subpage">
            {stats.map(stat => (
              <div className="ccl-stat" key={stat.id}>
                <h2 id={stat.id}>{stat.stat}</h2>
                <h4>{stat.about}</h4>
              </div>
            ))}
          </div>
        </VisibilitySensor>
      </div>
    </div>
  );
};

export default CommunityStats;
